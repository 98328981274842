<template>
 <div class="v_main g-flex-column">
  <head-back :isBack="false">
    <template>
      <span>首页</span>
    </template>
  </head-back>
  <div class="v-main-content g-ios-scrolling">
    <div class="v-main-top g-flex-column g-flex-align-center g-flex-justify-center">
      <div class="v-main-top-list g-flex-align-center">
        <div class="v-main-top-list-item g-flex-column g-flex-align-center">
          <p class="v-main-top-list-item-title">可<span>用余额</span></p>
          <p class="v-main-top-list-item-value">{{totalMoney.principal | filtersInt }}<span>{{totalMoney.principal | filtersFloat }}</span></p>
        </div>
        <div class="v-main-top-list-item g-flex-column g-flex-align-center">
          <p class="v-main-top-list-item-title">提<span>现中</span></p>
          <p class="v-main-top-list-item-value">{{totalMoney.cash_money | filtersInt }}<span>{{totalMoney.cash_money | filtersFloat }}</span></p>
        </div>
      </div>
    </div>
    <!-- 今日收入 -->
    <div class="v-main-total-list g-flex-align-center g-flex-justify-center">
      <div class="v-total-item">
        <span>今日收入</span>
        <span>{{ totalMoney.today_money }}</span>
      </div>
      <div class="v-total-item">
        <span>昨日收入</span>
        <span>{{ totalMoney.yesterday_money }}</span>
      </div>
    </div>

    <!-- 广播 v-if="noticeList[0].bullent" -->
    <van-notice-bar class="v-notice" v-if="noticeList[0].bullent" left-icon="volume-o" :scrollable="false" background="#EFF5FF" color="#75A1F3">
      <van-swipe vertical class="v-notice-swipe" :autoplay="2000" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in noticeList" :key="index" @click="noticeItemClick(item)">
          <div class="v-notice-item">
            <p>{{item.bullent_title}}</p>
            <span>更多</span>
          </div>
        </van-swipe-item>
      </van-swipe>
    </van-notice-bar>


    <div class="v-main-bottom">
      <ul class="v-main-bottom-list g-flex-align-center">
        <li class="v-main-bottom-list-item g-flex-align-center" @click="$router.push({ name: 'moneydetail' })">
          <i class="iconfont iconshouyi"></i>
          <span class="v-main-bottom-list-item-title">我的收益</span>
        </li>
        <li class="v-main-bottom-list-item g-flex-align-center" @click="$router.push({ name: 'mystore' })">
          <i class="iconfont iconshangjia"></i>
          <span class="v-main-bottom-list-item-title">我的商家</span>
        </li>
      </ul>
      <ul class="v-main-bottom-list g-flex-align-center">
        <li class="v-main-bottom-list-item g-flex-align-center" @click="$router.push({ name: 'myywy' })">
          <i class="iconfont iconceshikehu"></i>
          <span class="v-main-bottom-list-item-title">我的业务员</span>
        </li>
        <li class="v-main-bottom-list-item g-flex-align-center" @click="$router.push({ name: 'myinvite' })">
          <i class="iconfont iconyaoqingma"></i>
          <span class="v-main-bottom-list-item-title">我的邀请码</span>
        </li>
      </ul>
    </div>


  </div>
  <!-- 公告弹窗 -->
  <notice-pop ref="NoticePop"/>
 </div>
</template>

<script>
import HeadBack from '../../components/HeadBack.vue'
import NoticePop from '../../components/NoticePop.vue'
import { apiGetUserInfo, apiGetTotal } from '@/utils/api.js'
export default {
  components: { HeadBack, NoticePop },
  data() {
    return {
      totalMoney: {
        today_money: '',
        principal: '',
        cash_money: '',
        yesterday_money: '',
      }
    }
  },
  async created() {
    // 获取今日收入数据
    await this.apiGetTotalHandel()
    // 获取用户信息
    await this.apiGetUserInfoHandel()
  },
  filters: {
    filtersInt(val) {
      return val.substring(0, val.indexOf('.') + 1)
    },
    filtersFloat(val) {
      return val.substring(val.indexOf('.') + 1)
    }
  },
  methods: {
    // 公告每一项点击
    noticeItemClick(item) {
      this.$refs.NoticePop.onShow(true, item)
    },

    // 获取首页 今日收入数据
    async apiGetTotalHandel() {
      const { success, data } = await apiGetTotal()
      if(!success) return
      // principal //本金余额
      // commission //佣金余额
      this.totalMoney = data.data
      console.log(data)
    },

    // 获取用户信息
    async apiGetUserInfoHandel() {
      this.$global.loadingShow = true
      const { success, data } = await apiGetUserInfo()
      if(!success) return
      this.userInfo = data.data
      this.$global.setUserInfo(data.data)
      console.log(data)
    },

  },
  computed: {
      noticeList() {
        var bullentObj = this.$global.config.AGENT_BULLETIN
        if(!bullentObj.bullent) return [ { bullent: '', bullent_title: '' }]
        let flag = this.$global.isShowNotice
        console.log(flag)
        console.log(bullentObj)
        if(flag == 1) {
        // 如果没通知就不要展示了
        if(bullentObj.bullent) {
          this.$nextTick(() => {
            this.$refs.NoticePop.onShow(true, bullentObj)
          })
        } 
        this.$global.setShowNotice('0')
      }
        return [bullentObj]
      }
    }
}
</script>

<style lang='scss'>
.v_main {
 height: 100%;
 overflow: auto;
 .v-main-content {
  flex: 1;
  overflow-y: auto;
  background: #F1F1F1;
  .v-main-top {
      background: url('/image/daili_main_top_bg.png') no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 168px;
      .v-main-top-list {
        align-self: stretch;
        .v-main-top-list-item {
          flex: 1;
          color: $white;
          .v-main-top-list-item-title {
            font-size: 30px;
            span {
              font-size: 18px;
            }
          }
          .v-main-top-list-item-value {
            font-size: 30px;
            padding-top: 10px;
            span {
              font-size: 20px;
            }
          }
        }
      }
    }
    .v-main-total-list {
      margin-top: 5px;
      padding: 10px 0;
      background: $main_color;
      color: $white;
      .v-total-item {
        line-height: 1;
        font-size: 14px;
        padding: 0 6px;
        border-right: 1px solid $white;
        &:nth-last-of-type(1) {
          border-right: none;
        }
        span {
          padding: 0 1px;
        }
      }
    }

    .v-notice {
      .van-notice-bar__content {
        width: 100%;
        .v-notice-swipe {
          height: 40px;
          line-height: 40px;
          width: 100%;
          .v-notice-item {
            display: flex;
            justify-content: space-between;
            p {
              flex: 1;
              @include textManyOverflow(1)
            }
          }
        }
      }
    }

    .v-main-bottom {
      padding: 30px 15px;
      .v-main-bottom-list {
        margin-top: 26px;
        .v-main-bottom-list-item {
          background: $white;
          flex: 1;
          padding: 12px 18px;
          margin-right: 9px;
          border-radius: 12px;
          border: 1px solid #75A1F3;
          &:nth-of-type(2) {
            margin-right: 0;
            margin-left: 9px;
          }
          .iconfont {
            font-size: 24px;
            line-height: 24px;
            padding: 5px;
            background: #DAE7FE;
            border-radius: 50%;
            color: #4D71A0;
          }
          .v-main-bottom-list-item-title {
            color: $main_color;
            font-size: 13px;
            letter-spacing: 1px;
            padding-left: 14px;
          }
        }
        &:nth-of-type(1) {
          margin-top: 0;
        }
      }
    }
 }
}
</style>